<template>
  <div class="text-center">
    <div class="home-panel">
      <template v-for="category of serviceCategories">
        <div :key="category" v-if="filterServicesByCategory(enabledServices, category).length">
          <div class="category-label">{{category}}</div>
          <div class="category-list">
            <a :href="service.link" v-for="service in filterServicesByCategory(enabledServices, category)" :key="service.key">
              <div class="app-tile">
                <img class="app-image" :src="getImgUrl(service.icon)" :alt="service.name">
              </div>
            </a>
          </div>
        </div>
      </template>
    </div>
    <div>Services can be added or removed from the settings menu (changes will be saved)</div>
    <div class="tesloz">
      <a href="http://tesloz.com.au">
        <figure><img :src="getImgUrl('tesloz.png')"></figure>
        <span>A refined selection of accessories for your Tesla</span>
      </a>
    </div>
    <div id="donate_link" style="display:block;" onmouseup="return showLoader()">
      <center>
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick">
          <input type="hidden" name="hosted_button_id" value="KBRETH8TQEPAA">
          <figure>
            <input type="image" :src="getImgUrl('donateqr.png')" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" border="0">
            <figcaption>Click or scan QR Code to Donate</figcaption>
          </figure><img alt="" src="https://www.paypal.com/en_AU/i/scr/pixel.gif" width="1" height="1" border="0">
        </form>
      </center>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'HomePanel',
  computed: {
    ...mapGetters('data', ['enabledServices']),
    ...mapState('data', ['serviceCategories']),
  },
  methods: {
    getImgUrl: function(iconPath) {
      let image = null;
      try {
        image = require(`../assets/logos/${iconPath}`)
      } catch {
        // igonre error
      }
      return image
    },
    filterServicesByCategory: function(services, category) {
      return services.filter(service => service.category === category)
    }
  }
}
</script>

<style scoped lang="scss">
.home-panel {
  display: flex;
  flex-direction: column;
  padding: 35px 60px 15px;

  @media only screen and (max-width: 600px) {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.category-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.category-label {
  font-size: 1.2em;
  text-align: left;
  text-transform: capitalize
}
.app-tile {
  max-width: 240px;
  height: 100%;
  max-height: 135px;
  padding: 20px 35px;
}
.app-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
figcaption {
  color: #FFF;
}

figure {
  margin: 0;
  overflow: hidden;
  vertical-align: middle;
}
.tesloz {
  background-color: #E31937;
  border-radius: 5px;
  color: #fff;
  height: 40px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 50px;
  padding: 5px;
  text-align: center;
  width: 350px;
}

.tesloz a {
  margin-right: 0px;
  width: 100%;
  text-decoration: none;
}

.tesloz img {
  background-color: transparent;
  height: 20px;
  border: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.tesloz span {
  color: #fff;
  display: block;
  margin-top: -3px;
  font-size: 13px;
}

#donate_link {
  margin-top: 20px;
}

#donate_link input[type="image"] {
  height: 200px;
  width: 200px;
}
</style>
